/** 新闻详情 */
<template>
  <div class="details content">
    <el-breadcrumb v-if="$isPc" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/news' }">新闻中心</el-breadcrumb-item>
      <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="justify-between pb-30">
      <section class="details-left flex-1">
        <div v-if="Object.keys(article).length" class="details-main back-fff border-r8 mb-30">
          <h2 class="details-title">{{ article.title }}</h2>
          <p class="details-record flex-wrap justify-around">
            <!-- <Share v-if="$isPc" /> -->
            <span>发布时间：{{ article.release_at | filterTime }}</span>
            <span>发布来源：{{ article.unit }}</span>
            <span v-if="article.tag && article.tag.length">文章标签：
              <template v-for="(item, index) in article.tag">
                {{ item }}{{ index < article.tag.length - 1 ? '、' : '' }} </template>
            </span>
          </p>
          <el-divider></el-divider>
          <div class="details-con" v-html="article.details"></div>
        </div>
        <el-empty v-else description="暂无该政策"></el-empty>
      </section>
    </div>
  </div>
</template>
<script>
import { setShare } from '@/api/index'
import { getNewsDetails } from '@/api/news'
import { getItem } from '@/utils/storage'
import wx from 'weixin-js-sdk'
import Share from '@/components/Share.vue'
export default {
  name: 'NewsDetails',
  components: {
    Share,
  },
  data () {
    return {
      article: {}, //文章详情
    }
  },
  watch: {
    '$route.query.id': {
      handler (newVal, oldVal) {
        this.getNewsDetails()
      },
      deep: true,
    },
  },
  mounted () {
    this.getNewsDetails()
  },
  methods: {
    // 文章详情
    async getNewsDetails () {
      try {
        let { data } = await getNewsDetails(this.$route.query.id)
        this.article = data || {}
      } catch (error) { }
    },
    // 分享
    async setShare () {
      let links = encodeURIComponent(window.location.href.split('#')[0])
      // ios系统需使用第一次进入网站时的地址
      if (getItem('isIOS')) {
        links = encodeURIComponent(getItem('firstUrl'))
      }
      let { data } = await setShare(links)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
      })
      let options = {
        title: this.article.title, // 分享标题
        desc: this.$settleHtml(this.article.introduction), // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.$base + this.article.thumbnail, // 分享图标
      }
      wx.ready(function () {
        wx.updateAppMessageShareData({
          ...options,
          success: function () {
            // alert('分享朋友成功')
          },
        })
        wx.updateTimelineShareData({
          ...options,
          success: function () {
            // alert('分享朋友圈成功')
          },
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.details-left {

  // 文章主体
  .details-main {
    padding: 40px 0;
    text-align: center;

    .details-title {
      font-size: 24px;
      line-height: 28px;
    }

    .details-record {
      margin-top: 43px;
      color: #666666;
      line-height: 30px;

      span {
        text-align: left;
        padding: 0 10px;
      }
    }

    .el-divider--horizontal {
      margin: 35px 0;
    }
  }
}

@media (max-width: 767px) {
  .details-left {
    .details-main {
      margin-bottom: 0;

      .details-title {
        font-size: 20px;
        line-height: 24px;
      }

      .details-record {
        margin-top: 25px;
      }

      .el-divider--horizontal {
        margin: 30px 0 25px;
      }
    }
  }
}
</style>
